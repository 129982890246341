<template>
  <div class="news page">
    <div class="banner">
      <img src="@/assets/img/goods_bg.jpg" alt="">
    </div>
    <div class="flex">
      <div v-for="(item, i) in list" :key="i" class="item">
        <el-image @click="getDetail(item.id)" style="width: 380px; height: 230px;" :src="item.img" class="img" fit="cover" />
        <div style="
            border-bottom: 1px dashed gray;
            padding: 5px 0;
            width: 340px;
            margin-left: 14px;
            display: inline-block;
          ">
          {{ item.name }}
        </div>
        <div style="
            color: red;
            position: absolute;
            right: 20px;
            line-height: 37px;
            font-size: 24px;
          ">
          {{ item.current_price }}
        </div>
        <div style="height: 40px"></div>
      </div>
    </div>

    <div class="pagination">
      <el-pagination :hide-on-single-page="true" :page-size="pageSize" :total="total" class="page-box" background layout="prev, pager, next" @current-change="pageChange" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Server",
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 0,
      list: [],
    };
  },
  mounted() {},
  created() {
    this.getServer();
  },
  methods: {
    // 新闻资讯
    getServer() {
      this.$fecth
        .post("goods/getGoodsList", {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          if (res) {
            this.list = res.list;
            this.total = res.count;
          }
        });
    },
    pageChange(page) {
      this.pageNum = page;
      this.getServer();
    },
    getDetail(id) {
      this.$router.push({
        path: "/goodsInfo",
        name: "goodsInfo",
        query: { id: id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.news {
  padding-bottom: 60px;
  .pagination {
    padding: 40px 0;

    .page-box {
      text-align: center;
    }
  }
  .banner {
    margin-top: 20px;
    text-align: center;
    img {
      width: 100%;
    }
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .item {
    width: 380px;
    margin-bottom: 30px;
    margin-top: 30px;
    display: inline-block;
    position: relative;
    border: 1px solid #9e9e9e57;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    // margin-left: 18px;
    // border-radius: 10px 10px 0 0;
  }
}
</style>
